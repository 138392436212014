const maths = Array.from(document.getElementsByTagName('math'))

if (maths.length) {
  const operators = [
    '=', '\u2261', '\u223c', '\u2248', '<', '>',
    '+', '-', '\u00d7', '/', '\u00f7', '\u2062',
    '(', ')', '[', ']', 'mod', ',', '!', '\u2061',
    '&', '|', '^'
  ]

  const number = '-?\\d+(?:\.\\d+)?'
  const others = ['[a-zA-Z_]+', '\u03c0', '  ', '\u00bd', number]

  const all = others.concat(operators.map(op => {
    return op.replace(/\W/, ch => {
      return '\\u' + ('000' + ch.charCodeAt(0).toString(16)).slice(-4)
    })
  }))

  const re = new RegExp('((?:' + all.join(')|(?:') +'))', 'gi')
  const numberRe = new RegExp(number)

  const functions = ['f', 'g', 'h', 'gcd', 'lcm', 'sin', 'cos', 'tan', 'exp', 'log']

  maths.forEach(el => {
    const text = el.textContent

    if (!text || el.innerHTML.contains('<')) {
      return
    }

    const parts = text.trim().split(re).filter(part => {
      return part && part !== ' '
    })

    const html = parts.map(match => {
      const escaped = match.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')

      if (operators.contains(match)) {
        return `<mo>${escaped}</mo>`
      }
      else if (numberRe.test(match)) {
        return `<mn>${escaped}</mn>`
      }
      else if (match === '  ') {
        return '<mspace width="1em"></mspace>'
      }
      else if (match === '\u00bd') {
        return '<mfrac><mn>1</mn><mn>2</mn></mfrac>'
      }

      return `<mi>${escaped}</mi>`
    })

    for (let index = 1; index < parts.length - 1; ++index) {
      const part = parts[index]

      if (part === '^') {
        html[index] = `<msup>${html[index - 1]} ${html[index + 1]}</msup>`
        html[index - 1] = html[index + 1] = ''
      } else if (part === '(') {
        const fn = parts[index - 1]

        if (functions.contains(fn)) {
          html[index] = `<mo>&#8289;</mo>${html[index]}`
        }
      }
    }

    el.innerHTML = html.join('')
  })

  const scripts = [
    'https://polyfill.io/v3/polyfill.min.js?features=es6',
    'https://cdn.jsdelivr.net/npm/mathjax@3.0.5/es5/mml-svg.js'
  ]

  let timer

  const nextScript = () => {
    clearTimeout(timer)

    const url = scripts.shift()

    if (url) {
      const script = document.createElement('script')
      script.setAttribute('src', url)

      script.onload = nextScript

      document.head.appendChild(script)

      timer = setTimeout(nextScript, 5000)
    }
  }

  setTimeout(nextScript, 10)
}
