const methods = {
  contains (str) {
    return this.indexOf(str) !== -1
  },

  endsWith (str) {
    return this.indexOf(str, this.length - str.length) !== -1
  },

  startsWith (str) {
    return this.lastIndexOf(str, 0) === 0
  },

  trim () {
    return this.trimLeft().trimRight()
  },

  trimLeft () {
    return this.replace(/^\s+/, '')
  },

  trimRight () {
    return this.replace(/\s+$/, '')
  }
}

const string = String.prototype

for (const name in methods) {
  if (!string[name]) {
    string[name] = methods[name]
  }
}
