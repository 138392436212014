const methods = {
  contains (element) {
    return this.indexOf(element) !== -1
  },

  every (iterator, scope) {
    const len = this.length

    for (let index = 0; index < len; ++index) {
      if (index in this && !iterator.call(scope, this[index], index, this)) {
        return false
      }
    }

    return true
  },

  filter (iterator, scope) {
    const out = []
    const len = this.length

    for (let index = 0; index < len; ++index) {
      if (index in this) {
        const next = this[index]

        if (iterator.call(scope, next, index, this)) {
          out.push(next)
        }
      }
    }

    return out
  },

  first () {
    return this[0]
  },

  forEach (iterator, scope) {
    const len = this.length

    for (let index = 0; index < len; ++index) {
      if (index in this) {
        iterator.call(scope, this[index], index, this)
      }
    }
  },

  indexOf (element, startIndex) {
    const len = this.length
    let index = Number(startIndex) || 0

    if (index < 0) {
      index += len
    }

    index = Math.max(0, index)

    for ( ; index < len; ++index) {
      if (index in this && this[index] === element) {
        return index
      }
    }

    return -1
  },

  last () {
    return this[this.length - 1]
  },

  lastIndexOf (element, startIndex) {
    const len = this.length
    let index = Number(startIndex)

    index = (index === 0) ? 0 : (index || len - 1)

    if (index < 0) {
      index += len
    }

    index = Math.min(index, len - 1)

    for ( ; 0 <= index; --index) {
      if (index in this && this[index] === element) {
        return index
      }
    }

    return -1
  },

  map (iterator, scope) {
    const out = []
    const len = this.length

    out.length = this.length

    for (let index = 0; index < len; ++index) {
      if (index in this) {
        out[index] = iterator.call(scope, this[index], index, this)
      }
    }

    return out
  },

  reduce (iterator, initial) {
    const len = this.length
    let index = 0
    let current = initial

    if (arguments.length === 1) {
      while (index < len) {
        if (index++ in this) {
          current = this[0]
          break
        }
      }
    }

    for ( ; index < len; ++index) {
      if (index in this) {
        current = iterator(current, this[index], index, this)
      }
    }

    return current
  },

  reduceRight (iterator, initial) {
    let index = this.length - 1
    let current = initial

    if (arguments.length === 1) {
      while (0 <= index) {
        if (index-- in this) {
          current = this[index + 1]
          break
        }
      }
    }

    for ( ; 0 <= index; --index) {
      if (index in this) {
        current = iterator(current, this[index], index, this)
      }
    }

    return current
  },

  remove (element) {
    const index = this.indexOf(element)

    if (index !== -1) {
      this.splice(index, 1)
    }

    // Don't return anything, avoiding future compatibility issues
  },

  some (iterator, scope) {
    const len = this.length

    for (let index = 0; index < len; ++index) {
      if (index in this && iterator.call(scope, this[index], index, this)) {
        return true
      }
    }

    return false
  }
}

const array = Array.prototype

for (const name in methods) {
  if (!array[name]) {
    array[name] = methods[name]
  }
}

if (!Array.from) {
  Array.from = function (arr) {
    if (arr instanceof Array) {
      return arr
    }

    const out = []
    const len = arr.length

    for (let index = 0; index < len; ++index) {
      out.push(arr[index])
    }

    return out
  }
}

if (!Array.isArray) {
  Array.isArray = function (object) {
    return object instanceof Array
  }
}
