(function() {
  getElementsByClassName('pre').forEach(preStyling);
  getElementsByClassName('code-css').forEach(cssStyling);
  getElementsByClassName('code-js').forEach(jsStyleTag);
  getElementsByClassName('code-html').forEach(htmlStyleTag);

  window.Skirtle = window.Skirtle || {};

  Skirtle.ColorCode = {
    cssStyling: cssStyling,
    htmlStyling: htmlStyleTag,
    jsStyling: jsStyleTag,
    preStyling: preStyling
  };

  Skirtle.getElementsByClassName = getElementsByClassName

  function getElementsByClassName(className) {
    // Recent browsers...
    if (document.getElementsByClassName) {
      return Array.from(document.getElementsByClassName(className));
    }

    // Olders browsers, up to IE8 and FF2 - note FF didn't support document.all
    if (document.getElementsByTagName) {
      return Array.from(document.getElementsByTagName('*')).filter(function(element) {
        return element.className.split(' ').contains(className);
      });
    }

    // No known browser falls through to here
    return [];
  }

  function preStyling(tag) {
    tag.innerHTML = nonBreakingSpaces(whiteSpaceStyling(getText(tag)));
  }

  function cssStyling(tag) {
    setText(tag, cssStyleText(getText(tag)));
  }

  function jsStyleTag(tag) {
    setText(tag, jsStyleText(getText(tag)));
  }

  function cssStyleText(text) {
    var code = cssParse(text);

    var cssKeywords = [
      'solid', 'dashed', 'dotted',
      'bold', 'italic', 'normal',
      'serif', 'sans-serif', 'monospace', 'cursive', 'fantasy',
      'no-repeat',
      'left', 'right', 'top', 'bottom', 'center',
      'none', 'inherit', 'initial',
      'inline-block', 'inline', 'block',
      'relative', 'absolute', 'static',
      'underline', 'line-through', 'overline',
      'content-box', 'border-box',
      'collapse',
      'inset',
      'small-caps', 'uppercase', 'lowercase', 'capitalize',
      'nowrap', 'pre',
      'inside', 'outside',
      'pointer', 'default', 'move', 'crosshair'
    ];

    code = code.map(function(chunk, index) {
      // Replace all spaces with non-breaking spaces so that IE will respect the pre-formatting
      chunk = nonBreakingSpaces(chunk);

      var nextChunk = code[index + 1] || '';

      if (chunk.startsWith('/*')) {
        // Comments
        return '<span class="code-comment code-css-comment">' + chunk + '</span>';
      }
      else if (chunk.startsWith('{')) {
        // Properties
        return chunk.replace(/^(\u00A0*)([\w\-]*):(\u00A0*)([^\u00A0][^;]*);/gm, function(match, space1, name, space2, value) {
          value = value.split('\u00A0').map(function(v) {
            if (cssKeywords.contains(v)) {
              return '<span class="code-keyword code-css-keyword">' + v + '</span>';
            }

            return v;
          }).join('\u00A0');

          return space1 + '<span class="code-css-property">' + name + '</span>:' + space2 + '<span class="code-css-value">' + value + '</span>;'
        });
      }
      else if (nextChunk.startsWith('{')) {
        // Selectors
        return '<span class="code-css-selector">' + chunk + '</span>';
      }

      return chunk;
    });

    code = whiteSpaceStyling(code.join(''));

    code = code.replace('!important', '<span class="code-keyword code-css-modifier">!important</span>');

    return code;
  }

  function jsStyleText(text) {
    // var jsKeywords = [
    //     'break', 'case', 'catch', 'continue', 'debugger', 'default', 'delete', 'do', 'else', 'false', 'finally',
    //     'for', 'function', 'if', 'in', 'instanceof', 'new', 'null', 'return', 'switch', 'this', 'throw', 'true',
    //     'try', 'typeof', 'undefined', 'var', 'void', 'while', 'with',
    //     'async', 'await', 'class', 'const', 'export', 'extends', 'import', 'let', 'of', 'super', 'yield'
    // ];

    var jsKeywords = [
      'break', 'case', 'catch', 'default', 'delete', 'do', 'else', 'false', 'finally', 'for', 'function', 'if',
      'in', 'instanceof', 'new', 'null', 'return', 'switch', 'this', 'throw', 'true', 'try', 'typeof', 'var',
      'while', 'with'
    ];

    // Parse the code into strings, comments, regexs and other
    var code = parse(text);

    code = code.map(function(chunk) {
      // Replace all spaces with non-breaking spaces so that IE will respect the pre-formatting
      chunk = nonBreakingSpaces(chunk);

      if (chunk.startsWith('"') || chunk.startsWith("'") || chunk.startsWith('`')) {
        // Strings
        // if (chunk.startsWith('`')) {
        //   chunk = chunk.replace(/\${([^}]*)}/g, function(all, match) {
        //     return '<span style="color:#c70;font-style: italic">${<span style="font-style: italic">' + match + '</span>}</span>';
        //   });
        // }

        chunk = chunk.replace(/&amp;#?\w+;/g, function(match) {
          return '<span class="code-js-string-html-entity">' + match + '</span>';
        })

        chunk = chunk.replace(/\\u[\da-f]{4}|\\[nrt'"`\\]/gi, function(match) {
          return '<span class="code-js-string-escape-sequence">' + match + '</span>';
        })

        return '<span class="code-string code-js-string">' + chunk + '</span>';
      }
      else if (chunk.startsWith('//') || chunk.startsWith('/*')) {
        // Comments
        return '<span class="code-comment code-js-comment">' + chunk + '</span>';
      }
      else if (chunk.startsWith('/')) {
        // Regex - TODO: strictly speaking a slash is not sufficient to detect a regex at this point
        return '<span class="code-regex code-js-regex">' + chunk + '</span>';
      }

      // Resort to regex... TODO: move this logic into the parser

      // Ellipses
      chunk = chunk.replace(/\.{3}/gm, '<span class="code-ellipsis">...</span>');

      // Numbers
      chunk = chunk.replace(/(\b0[xX][\da-fA-F]*|(?:\.\d+|\b\d+\.?\d*)(?:[eE][+\-]?\d*)?)/gm, '<span class="code-number code-js-number">$1</span>');

      // JavaScript keywords
      chunk = chunk.replace(new RegExp('\\b(' + jsKeywords.join('|') + ')\\b', 'gm'), '<span class="code-keyword code-js-keyword">$1</span>');

      return chunk;
    });

    code = whiteSpaceStyling(code.join(''));

    return code;
  }

  function htmlStyleTag(tag) {
    // Replace all spaces with non-breaking spaces so that IE will respect the pre-formatting
    var code = whiteSpaceStyling(nonBreakingSpaces(getText(tag)));

    // JavaScript in script tags
    code = code.replace(/(&lt;script.*?&gt;)(.*?)(&lt;\/script&gt;)/gm, function(match, capture1, capture2, capture3) {
      return capture1 + jsStyleText(capture2) + capture3;
    });

    code = code.replace(/&lt;[\w\/:].*?&gt;/gm, function(tag) {
      return '<span class="code-html-tag">' + tag.replace(
        // HTML attributes
        /([\w\-:]*)(\s*=\s*)(["'])([^\3]*?\3)/gm,
        '<span class="code-html-attribute-name">$1</span>$2<span class="code-html-attribute-value">$3$4</span>'
      ).replace(
        // HTML tags
        /^(&lt;\/?)([\w]*)/,
        '$1<span class="code-html-tag-name">$2</span>'
      ) + '</span>';
    });

    // HTML comments
    code = code.replace(/(&lt;!--.*?--&gt;)/gm, '<span class="code-comment code-html-comment">$1</span>');

    setText(tag, code);
  }

  function getText(tag) {
    return tag.innerHTML;
    // An alternative implementation, just in case the quirks of innerHTML prove troublesome
    // return Array.from(tag.childNodes).map(function(node) {
    //     return node.data;
    // }).join('');
  }

  function setText(tag, text) {
    tag.innerHTML = text;
  }

  function whiteSpaceStyling(code) {
    var lines = getLines(code);
    var newLines = adjustWhiteSpace(lines);

    // Recombine the lines, need the non-breaking space for IE
    code = newLines.join('\u00A0<br/>');

    return code;
  }


  function getLines(text) {
    // Normalize the newline characters and split the code into lines
    var lines = text.replace(/\r\n/gm, '\n').split('\n');

    // Purge blank lines from the start
    while (lines.length && !lines.first().replace(/\s/g, '')) {
      lines.shift();
    }

    // Purge blank lines from the end
    while (lines.length && !lines.last().replace(/\s/g, '')) {
      lines.pop();
    }

    return lines;
  }

  function adjustWhiteSpace(lines) {
    // Find the smallest amount of whitespace at the start of a line
    var minIndex = Math.min.apply(Math, lines.map(function(line) {
      // Index of the first non-whitespace character
      return line.search(/[^ \u00A0]/);
    }).filter(function(minWhiteSpace) {
      // Filter out lines with no non-whitespace
      return minWhiteSpace !== -1;
    }));

    // Adjust all whitespace based on the minimum amount
    return lines.map(function(line) {
      return line.substring(minIndex);
    });
  }

  function nonBreakingSpaces(str) {
    return str.replace(/\u0020/gm, '\u00A0');
  }

  function cssParse(code) {
    var index = 0,
        lastIndex = 0,
        len = code.length,
        stack = [],
        spaceRe = /\s/,
        nextChar,
        nextTwoChars;

    for ( ; index < len ; ++index) {
      nextChar = code.charAt(index);
      nextTwoChars = code.slice(index, index + 2);

      flush(index);

      if (spaceRe.test(nextChar)) {
        readWhiteSpace();
      }
      else if (nextTwoChars === '/*') {
        readMultilineComment();
      }
      else if (nextChar === '{') {
        readRuleBlock();
      }
      else {
        readSelectors();
      }
    }

    flush(len);

    return stack;

    function readRuleBlock() {
      for ( ; index < len ; ++index) {
        if (code.charAt(index) === '}') {
          break;
        }
      }

      flush(index + 1);
    }

    function readWhiteSpace() {
      for ( ; index < len ; ++index) {
        if (!spaceRe.test(code.charAt(index))) {
          --index;
          break;
        }
      }

      flush(index + 1);
    }

    function readMultilineComment() {
      index += 2;

      for ( ; index < len ; ++index) {
        if (code.slice(index, index + 2) === '*/') {
          ++index;
          break;
        }
      }

      flush(index + 1);
    }

    function readSelectors() {
      for ( ; index < len ; ++index) {
        if (code.charAt(index) === '{') {
          --index;
          break;
        }
      }

      flush(index + 1);
    }

    function flush(end) {
      if (end > len) {
        end = len;
      }

      if (lastIndex !== end) {
        stack.push(code.slice(lastIndex, end));
        lastIndex = end;
      }
    }
  }

  // A simple parser that splits the string into an array of strings, comments, regexs and other
  function parse(code) {
    var index = 0,
        lastIndex = 0,
        len = code.length,
        stack = [],
        nextChar,
        nextTwoChars;

    for ( ; index < len ; ++index) {
      nextChar = code.charAt(index);
      nextTwoChars = code.slice(index, index + 2);

      if (nextChar === '\'' || nextChar === '"' || nextChar === '`') {
        flush(index);
        readString();
      }
      else if (nextTwoChars === '//') {
        flush(index);
        readLineComment();
      }
      else if (nextTwoChars === '/*') {
        flush(index);
        readMultilineComment();
      }
      else if (nextChar === '/') {
        var lastChars = code.slice(lastIndex, index).trim();

        // TODO: Figure out a definitive list of characters
        // Note: if lastChars is empty it counts as a match
        if (',;+-/*:=><(!%?'.contains(lastChars.slice(-1))) {
          flush(index);
          readRegex();
        }
      }
    }

    flush(len);

    return stack;

    function readString() {
      var escapeSequence = false;
      ++index;

      forLabel: for ( ; index < len ; ++index) {
        if (escapeSequence) {
          escapeSequence = false;
        }
        else {
          switch (code.charAt(index)) {
            case '\\':
              escapeSequence = true;
              break;
            case nextChar: // Same quote that opened the string
              break forLabel;
            case '\n': // If we hit a newline we have a syntax error... gloss over it for now
              --index;
              break forLabel;
          }
        }
      }

      flush(index + 1);
    }

    function readLineComment() {
      for ( ; index < len ; ++index) {
        if (code.charAt(index) === '\n') {
          --index;

          if (code.charAt(index) === '\r') {
            --index;
          }

          break;
        }
      }

      flush(index + 1);
    }

    function readMultilineComment() {
      index += 2;

      for ( ; index < len ; ++index) {
        if (code.slice(index, index + 2) === '*/') {
          ++index;
          break;
        }
      }

      flush(index + 1);
    }

    function readRegex() {
      var escapeSequence = false;
      ++index;

      forLabel: for ( ; index < len ; ++index) {
        if (escapeSequence) {
          escapeSequence = false;
        }
        else {
          switch (code.charAt(index)) {
            case '\\':
              escapeSequence = true;
              break;
            case '/':
              break forLabel;
            case '\n': // If we hit a newline we have a syntax error... gloss over it for now
              --index;
              break forLabel;
          }
        }
      }

      while ('gim'.contains(code.charAt(index + 1)) && index < len) {
        index++;
      }

      flush(index + 1);
    }

    function flush(end) {
      if (end > len) {
        end = len;
      }

      if (lastIndex !== end) {
        stack.push(code.slice(lastIndex, end));
        lastIndex = end;
      }
    }
  }
})();
